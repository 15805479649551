import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Beranda from "./pages/home/home";
import Tentang from "./pages/about/about";
import Pelayanan from "./pages/service/service";
import Kontak from "./pages/contact/contact";
import Navbar from "./screen/navbar/navbar";
import Footer from "./screen/footer/footer";
import OrderButton from "./screen/orders/orderbuttom";

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Beranda />} />
          <Route path="/about" element={<Tentang />} />
          <Route path="/service" element={<Pelayanan />} />
          <Route path="/contact" element={<Kontak />} />
        </Routes>
        <OrderButton />
        <Footer />
      </div>
    </Router>
  );
};

export default App;
